import React from "react";
import config from "../../config";
// http://www.schillmania.com/projects/snowstorm/
/* global SnowStorm */

class Card extends React.Component {
  constructor(props) {
    super(props);

    this.snowStorm = new SnowStorm(window, document);

    const [country] = config.countries.filter((country) => {
      return country.url === props.location.pathname;
    });

    const { location, history } = this.props;
    history.replace();

    const hasPopup = !location.state || !location.state.fromList;

    this.state = {
      country,
      isMuted: hasPopup,
      hasPopup,
    };

    this.toggleSound = this.toggleSound.bind(this);
    this.playSound = this.playSound.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
  }

  componentWillUnmount() {
    if (this.snowStorm) {
      this.snowStorm.stop();
      this.snowStorm = null;
    }
  }

  componentDidMount() {
    this.music = document.getElementById("music");
    this.music.currentTime = 0;
    this.music.muted = this.state.hasPopup;

    if (!this.state.hasPopup) {
      this.playSound();

      this.snowStorm.start();
    }
  }

  playSound() {
    try {
      this.music.play();
    } catch (e) {
      // console.dir(e);
    }
  }

  toggleSound() {
    this.music.muted = !this.state.isMuted;
    this.setState({
      isMuted: this.music.muted,
    });
  }

  hidePopup() {
    this.setState({
      isMuted: false,
      hasPopup: false,
    });

    this.snowStorm.start();

    this.music.muted = false;
    this.playSound();
  }

  render() {
    return (
      <div className="card">
        <div className="card__logo" />
        <button
          className="card__button"
          type="button"
          title={this.state.isMuted ? "Включить звук" : "Отключить звук"}
          aria-label={this.state.isMuted ? "Включить звук" : "Отключить звук"}
          aria-pressed={this.state.isMuted}
          onClick={this.toggleSound}
        />
        <div className="card__image" />
        <div className={`card__text card__text_${this.state.country.code}`}>
          <span className="v-hidden">{this.state.country.text}</span>
        </div>

        <div className="card__popup" role="alert" hidden={!this.state.hasPopup}>
          <div className="card__alert">
            <span className="v-hidden">Включите звук</span>
            <button
              className="card__ok"
              type="button"
              title="Ok"
              aria-label="Ok"
              onClick={this.hidePopup}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
