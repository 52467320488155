export const zpUrls = {
  // p1: "https://new-milka.arweb.app/?zid=z/kFCp1c&rs=0&menu=0&toolbar=0",
  p1: "https://www.mondelezinternational.com/Russia",
  // p2: "https://new-milka.arweb.app/?zid=z/1kkn1c&rs=0&menu=0&toolbar=0",
  p2: "https://promo.milka.ru/",
  // p3: "https://new-milka.arweb.app/?zid=z/ZAbk1c&rs=0&menu=0&toolbar=0",
  p3: "https://alpengold.me/",
};

const config = {
  countries: [
    {
      label: "Россия",
      code: "ru",
      url: "",
      isExternal: true,
    },
    {
      label: "Казахстан",
      code: "kz",
      url: "",
      isExternal: true,
    },
    {
      label: "Україна",
      code: "ua",
      url: "https://milka-promo.com.ua/",
      isExternal: true,
    },
    {
      //Грузия
      label: "საქართველო",
      text: "ნაზ წელს გისურვებთ!",
      code: "ge",
      url: "/georgia",
      isExternal: false,
    },
    {
      //Азербайджан
      label: "Azərbaycan",
      text: "Zərif Yeni İl arzulayırıq!",
      code: "az",
      url: "/azerbaijan",
      isExternal: false,
    },
    {
      //Узбекистан
      label: "O'zbekiston",
      text: "Mehribon Yil bo`lsin!",
      code: "uz",
      url: "/uzbekistan",
      isExternal: false,
    },
    {
      //Монголия
      label: "Монгол",
      text: "Өнөтэй жилийн мэнд!",
      code: "mn",
      url: "/mongolia",
      isExternal: false,
    },
    {
      //Кыргызстан
      label: "Кыргызстан",
      text: "Жылыңар назик болсун!",
      code: "kg",
      url: "/kyrgyzstan",
      isExternal: false,
    },
    {
      //Армения
      label: "Հայաստան",
      text: "Քնքուշ տարի՛ ենք ցանկանում",
      code: "am",
      url: "/armenia",
      isExternal: false,
    },
    // {
    //   //Турция
    //   label: "Türkiye",
    //   code: "tr",
    //   url: "#TODO", //TODO: проставить URL
    //   isExternal: true,
    // },
  ],
};

export default config;
