import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import "./App.scss";

import Countries from "./components/Countries/Countries";
import Card from "./components/Card/Card";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Countries} />
        <Route exact path="/:country" component={Card} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
